import React from "react";
import Logo from "../Images/Logo.jpeg"
import { 
    PageWrapper,
    ContentWrapper,
    LogoContainer,
    LogoImg,
    CompanyName,
    Description,
    LaunchDate,
 } from "./elements";

function ComingSoonPage() {
  return (
    <PageWrapper>
      <ContentWrapper>
        <LogoContainer>
          <LogoImg src={Logo} />
        </LogoContainer>
        <CompanyName>Mr Wests</CompanyName>
        <Description>CARIBBEAN CUISINE</Description>
        <LaunchDate>Coming Soon 2024</LaunchDate>
      </ContentWrapper>
    </PageWrapper>
  );
}

export default ComingSoonPage;
